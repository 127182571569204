import {
    PointsDetails,
    Transaction,
} from 'resourceDescriptions/points-transactions'
import { FilterOption, TwitchUser, StreamsStatsReportPayloadItem } from 'types'
import { z } from 'zod'
import apiRequest from './apiRequest'

type TransactionOrAggregate = Transaction | PointsDetails

export abstract class DaredropApi {
    static async searchTwitchGames(input: string): Promise<FilterOption[]> {
        if (!input) return []

        const res = await apiRequest({
            endpointId: 'SEARCH_TWITCH_GAMES',
            payload: {
                input,
                after: null,
                first: 10,
            },
            schema: z.object({
                data: z.array(z.object({ name: z.string(), id: z.string() })),
            }),
        })

        return res.data.map(
            ({ name, id }): FilterOption => ({
                label: name,
                value: id,
            })
        )
    }

    static async getStreamsStatsReportPayload(payload: {
        dateFrom: string
        dateTo: string
    }): Promise<StreamsStatsReportPayloadItem[]> {
        return apiRequest({
            endpointId: 'GET_STREAMS_STATS_REPORT_PAYLOAD',
            payload,
            schema: z.any(),
        })
    }

    static async searchTwitchUsers(input: string): Promise<TwitchUser[]> {
        if (!input) return []

        const res = await apiRequest({
            endpointId: 'SEARCH_TWITCH_USERS',
            payload: {
                input,
                after: null,
                first: 10,
            },
            schema: z.object({
                data: z.array(
                    z.object({
                        id: z.string(),
                        broadcaster_login: z.string(),
                        display_name: z.string(),
                    })
                ),
            }),
        })

        return res.data
    }

    static async sendSignedUpCreatorsReport({
        filterValue,
        filterName,
    }: {
        filterValue?: string
        filterName?: string
    }): Promise<{
        statusCode: number
        error?: { message: string }
    }> {
        return apiRequest({
            endpointId: 'INIT_SIGNED_UP_CREATORS_REPORT',
            payload: { filterValue, filterName },
            schema: z.any(),
        })
    }

    static async markTransactionAsPaidOffPlatform(
        item: TransactionOrAggregate
    ) {
        const getPayload = () => {
            if ('userId' in item) {
                return {
                    userId: item.userId,
                    dropEventId: item.dropEventId,
                }
            }

            return { transactionId: item.id }
        }

        await apiRequest({
            endpointId: 'CREATE_TRANSACTION_PAID_OFF_PLATFORM',
            payload: getPayload(),
            schema: z.any(),
        })
    }

    static async getPrivateDropCreatorsPayload(dropEventId: string) {
        return apiRequest({
            endpointId: 'GET_PRIVATE_DROP_EVENT_REPORT_PAYLOAD',
            payload: { dropEventId },
            schema: z.object({
                creatorIds: z.array(z.string()),
                dateFrom: z.string(),
                dateTo: z.string(),
                gameIds: z.array(z.coerce.string()),
            }),
        })
    }

    static async getDropShortLinksMappingReport(dropEventId: string) {
        return apiRequest({
            endpointId: 'GET_DROP_SHORT_LINKS_MAPPING_REPORT',
            payload: { id: dropEventId },
            schema: z.object({
                sheetData: z.array(
                    z.object({
                        creatorId: z.string(),
                        creatorChannelUrl: z.string().optional(),
                        daredropProfileUrl: z.string().optional(),
                        shortUrl: z.string(),
                        url: z.string(),
                    })
                ),
                fileUrl: z.string(),
            }),
        })
    }

    static async transferRewards(params: {
        reward: {
            id: string
            type: string
        }
        transferTo: string
        transferFrom: string
        amount: number
    }) {
        await apiRequest({
            endpointId: 'TRANSFER_REWARDS',
            payload: params,
            schema: z.any(),
        })
    }

    static async getUploadSignedUrl(params: {
        fileName: string
    }): Promise<{ signedUrl: string; fileName: string }> {
        return apiRequest({
            endpointId: 'GET_UPLOAD_SIGNED_URL',
            payload: params,
            schema: z.object({
                signedUrl: z.string(),
                fileName: z.string(),
            }),
        })
    }
}
