/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    ArrayInput,
    BooleanInput,
    Button,
    Edit,
    FormDataConsumer,
    minValue,
    NumberInput,
    RadioButtonGroupInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    Toolbar,
    useEditController,
    useInput,
    useNotify,
    useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from '@mui/material'
import requestHandler from 'utils/requestHandler'
import { daredropApiUrl } from 'constants/app'
import moment from 'moment'
import React, { ChangeEvent } from 'react'
import { useHelperText } from 'hooks/useHelperText'
import { pointsToDollarsHandler } from 'utils/pointsToDollars'
import {
    DarePlatform,
    DropEvent,
    DropEventBudgetType,
    DropEventSubscriptionStatus,
    TwitchUser,
} from 'types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { FormControl, TextField, TextFieldProps } from '@material-ui/core'
import styles from './styles/manageCampaign.module.scss'
import { ProductResolver } from '../../utils/drops/ProductResolver'
import { AdminAsyncFilterAutocomplete } from '../../components/AdminAsyncFilterAutocomplete'
import { DaredropApi } from '../../utils/daredrop-api'
import { hasValidDares } from '../../resourceDescriptions/manage-campaign'
import { TokensService } from '../../services/tokens.service'

enum PointsCalculationMethod {
    DEFAULT = 'default',
    CPVH = 'cpvh',
}

const ManageCampaign = () => {
    const controller = useEditController()
    return (
        <Edit
            mutationOptions={{
                onSuccess: async () => {
                    await controller.refetch()
                },
            }}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm redirect={false} toolbar={<SubmitToolbar />}>
                <CampaignManagementForm />
            </SimpleForm>
        </Edit>
    )
}

const CampaignManagementForm = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 4,
            }}
        >
            <StatusSection />
            <SettingsSection />
            <ValidationSection />
        </div>
    )
}

const StatusSection = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const isStatusSectionVisible = dropEvent.approved

    if (!isStatusSectionVisible) {
        return null
    }

    return (
        <div>
            <p>Status</p>
            <hr />
            <div style={{ display: 'flex', gap: 4 }}>
                <EnableInput />
                <DisableInput />
                <ResetDaresInput />
            </div>
        </div>
    )
}

const SettingsSection = () => {
    return (
        <div>
            <p>Settings</p>
            <hr />
            <ApproveInput />
            <ActivateInvoiceInput />
            <AwardPointsInput />
            <PointsSection />
            <DisableUserHasGameInput />
            <IsCommunityDropInput />
            <AutoApproveDeliveriesInput />
            <div style={{ display: 'flex', gap: 10, alignItems: 'baseline' }}>
                <ExpirationDateInput />
                <ExpirationReminderDateInput />
            </div>
            <RewardScalabilityInput />
            <DropImageInput />
        </div>
    )
}

const AutoApproveDeliveriesInput = () => {
    return (
        <BooleanInput
            label="Auto approve deliveries"
            source="enableAutoApprove"
        />
    )
}

const ValidationSection = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const isCampaignHasNoValidDaresInputVisible =
        !dropEvent.approved && !hasValidDares(dropEvent)
    const isCampaignHasNoInitialProductInputVisible =
        !dropEvent.approved && !dropEvent.initialProduct?.length

    const isValidationSectionVisible =
        isCampaignHasNoValidDaresInputVisible ||
        isCampaignHasNoInitialProductInputVisible

    if (!isValidationSectionVisible) {
        return null
    }

    return (
        <div>
            <p>Validation</p>
            <hr />
            <HasNoValidDaresInput />
            <HasNoInitialProductInput />
        </div>
    )
}

const SubmitToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
)

const RewardScalabilityInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const products = [
        ...new Map(
            dropEvent.dares.map((dare) => [dare.product.id, dare.product])
        ).values(),
    ]
    const isRewardScalabilityEnabled = useInput({
        source: 'rewardScalability.enabled',
    }).field.value

    const scalabilityRewards = (useInput({
        source: 'rewardScalability.rewards',
    }).field.value || []) as { rewardId: string }[]

    return (
        <div>
            <BooleanInput
                label="Reward scalability"
                source="rewardScalability.enabled"
            />
            {isRewardScalabilityEnabled && (
                <ArrayInput
                    disabled={!isRewardScalabilityEnabled}
                    source="rewardScalability.rewards"
                    label="Reward scalability"
                >
                    <SimpleFormIterator
                        disableReordering
                        getItemLabel={() => ''}
                    >
                        <SelectInput
                            helperText={false}
                            label="Reward"
                            source="rewardId"
                            choices={products.map((product) => {
                                const productResolver = new ProductResolver(
                                    product
                                )
                                const isUsed = scalabilityRewards.some(
                                    (reward) => reward.rewardId === product.id
                                )
                                return {
                                    id: productResolver.getId(),
                                    name: productResolver.getName(),
                                    disabled: isUsed,
                                }
                            })}
                        />
                        <ArrayInput source="increments" label="Increments">
                            <SimpleFormIterator
                                className={styles.rewardScalabilityFormIterator}
                                disableReordering
                                getItemLabel={() => ''}
                            >
                                <SelectInput
                                    label="Platform"
                                    source="platform"
                                    helperText={false}
                                    choices={[
                                        {
                                            id: DarePlatform.Twitch,
                                            name: 'Twitch',
                                        },
                                        {
                                            id: DarePlatform.YouTube,
                                            name: 'YouTube',
                                        },
                                        {
                                            id: DarePlatform.TikTok,
                                            name: 'TikTok',
                                        },
                                    ]}
                                />
                                <NumberInput
                                    label="Value"
                                    source="value"
                                    helperText="CCV/AVG VIEWS in last 30 days"
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            )}
        </div>
    )
}

const ResetDaresInput = () => {
    const controller = useEditController()
    const dropEvent = useRecordContext<DropEvent>()
    const notify = useNotify()
    return (
        <div
            style={{
                maxWidth: 170,
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Tooltip title="Reset dares limits">
                <div>
                    <Button
                        label="Reset dares"
                        size="large"
                        color="primary"
                        onClick={async () => {
                            await requestHandler<{
                                statusCode: number
                                body?: Record<string, any>
                                error?: Record<string, any>
                            }>({
                                url: daredropApiUrl,
                                method: 'POST',
                                body: {
                                    endpointId: 'RESET_CAMPAIGN_DARES',
                                    payload: {
                                        id: dropEvent.id,
                                    },
                                    authentication: TokensService.getAppToken(),
                                },
                                headers: {},
                            })
                            notify('Dares limits have been reset', {
                                type: 'info',
                            })
                            await controller.refetch()
                        }}
                    />
                </div>
            </Tooltip>
            <span style={{ fontSize: '0.7rem' }}>
                Resets all dares limits for this campaign
            </span>
        </div>
    )
}

const DisableInput = () => {
    const controller = useEditController()
    const dropEvent = useRecordContext<DropEvent>()
    const isDisableInputDisabled =
        !dropEvent.approved ||
        dropEvent.subscriptionStatus !== DropEventSubscriptionStatus.ACTIVE
    return (
        <div
            style={{
                maxWidth: 170,
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Tooltip
                title={
                    isDisableInputDisabled ? 'Campaign is already disabled' : ''
                }
                placement="top"
            >
                <div>
                    <Button
                        label="Disable"
                        size="large"
                        color="primary"
                        disabled={isDisableInputDisabled}
                        onClick={async () => {
                            await requestHandler<{
                                statusCode: number
                                body?: Record<string, any>
                                error?: Record<string, any>
                            }>({
                                url: daredropApiUrl,
                                method: 'POST',
                                body: {
                                    endpointId: 'CHANGE_CAMPAIGN_STATUS',
                                    payload: {
                                        action: 'DISABLE',
                                        dropEventId: dropEvent.id,
                                        brandId: dropEvent.brand.id,
                                    },
                                    authentication: TokensService.getAppToken(),
                                },
                                headers: {},
                            })
                            await controller.refetch()
                        }}
                    />
                </div>
            </Tooltip>
            <span style={{ fontSize: '0.7rem' }}>
                Marks campaign as disabled, campaign will not be shown to users
                anymore, operation can be reverted
            </span>
        </div>
    )
}

const EnableInput = () => {
    const controller = useEditController()
    const dropEvent = useRecordContext<DropEvent>()
    const isEnableInputDisabled =
        !dropEvent.approved ||
        dropEvent.subscriptionStatus === DropEventSubscriptionStatus.ACTIVE
    return (
        <div
            style={{
                maxWidth: 170,
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Tooltip
                title={
                    isEnableInputDisabled ? 'Campaign is already enabled' : ''
                }
                placement="top"
            >
                <div>
                    <Button
                        label="Enable"
                        size="large"
                        color="primary"
                        disabled={isEnableInputDisabled}
                        onClick={async () => {
                            await requestHandler<{
                                statusCode: number
                                body?: Record<string, any>
                                error?: Record<string, any>
                            }>({
                                url: daredropApiUrl,
                                method: 'POST',
                                body: {
                                    endpointId: 'CHANGE_CAMPAIGN_STATUS',
                                    payload: {
                                        action: 'ENABLE',
                                        dropEventId: dropEvent.id,
                                        brandId: dropEvent.brand.id,
                                    },
                                    authentication: TokensService.getAppToken(),
                                },
                                headers: {},
                            })
                            await controller.refetch()
                        }}
                    />
                </div>
            </Tooltip>
            <span style={{ fontSize: '0.7rem' }}>
                Marks campaign as enabled, campaign will be shown to users,
                operation can be reverted
            </span>
        </div>
    )
}

const ApproveInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const isApproveInputVisible = !dropEvent.approved
    if (!isApproveInputVisible) {
        return null
    }
    return <BooleanInput label="Approve" source="approved" />
}

const ActivateInvoiceInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const isActivateInvoiceInputVisible =
        !dropEvent.approved &&
        dropEvent.subscriptionStatus !== DropEventSubscriptionStatus.ACTIVE &&
        dropEvent.budget?.type === DropEventBudgetType.CAMPAIGN
    if (!isActivateInvoiceInputVisible) {
        return null
    }
    return <BooleanInput label="Activate Invoice" source="activateInvoice" />
}

const AwardPointsInput = () => {
    return (
        <BooleanInput
            label="Campaign should award points"
            source="awardPoints"
        />
    )
}

const DisableUserHasGameInput = () => {
    return (
        <BooleanInput
            label="Disable 'User has game' behavior"
            source="disableUserHasGame"
        />
    )
}

const DropImageInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const image =
        dropEvent.gameObj?.image ?? dropEvent?.dares?.[0]?.games?.[0]?.image
    const imageInput = useInput({
        source: 'image',
        defaultValue: `https://${image?.replace('https://', '')}`,
    })
    const imageSrc = imageInput.field.value
    if (dropEvent.isMultiGame) {
        return null
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
            }}
        >
            <label htmlFor="image">Image</label>
            <label
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px dashed #ccc',
                    width: 320,
                    height: 135,
                    padding: 10,
                    cursor: 'pointer',
                    borderRadius: 5,
                    overflow: 'hidden',
                }}
            >
                {imageSrc ? (
                    <img
                        style={{
                            width: 320,
                            height: 135,
                            objectFit: 'cover',
                            objectPosition: 'top',
                        }}
                        src={imageSrc}
                        alt="Game"
                    />
                ) : (
                    <span>+ Upload image</span>
                )}
                <input
                    id="image"
                    name="image"
                    style={{
                        display: 'none',
                    }}
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                        const file = event.target.files?.[0]
                        if (!file) {
                            return
                        }
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            imageInput.field.onChange(e.target?.result)
                        }
                        reader.readAsDataURL(file)
                    }}
                />
            </label>
        </div>
    )
}

const IsCommunityDropInput = () => {
    return (
        <BooleanInput
            label="This is a Community Drop"
            source="isCommunityDrop"
        />
    )
}

const ExpirationDateInput = () => {
    const { field, fieldState } = useInput({
        defaultValue: null,
        source: 'expirationDate',
        validate: [minValue(moment().add(1, 'day').format('YYYY-MM-DD'))],
    })
    return (
        <FormControl error={Boolean(fieldState.error)}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Campaign expiration date"
                    value={field.value ? new Date(field.value) : null}
                    onChange={(date) => {
                        field.onChange(
                            moment(date).set('hour', 10).utc().format()
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...(params as TextFieldProps)}
                            size="small"
                            variant="filled"
                            defaultValue={null}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </LocalizationProvider>
        </FormControl>
    )
}

const ExpirationReminderDateInput = () => {
    const { field, fieldState } = useInput({
        defaultValue: null,
        source: 'expirationReminderDate',
        validate: [minValue(moment().add(1, 'day').format('YYYY-MM-DD'))],
    })
    return (
        <FormControl error={Boolean(fieldState.error)}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    views={['day', 'hours']}
                    label="Dare expiration reminder date"
                    value={field.value ? new Date(field.value) : null}
                    onChange={(date) => {
                        field.onChange(
                            moment(date).startOf('hour').utc().format()
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...(params as TextFieldProps)}
                            size="small"
                            variant="filled"
                            defaultValue={null}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </LocalizationProvider>
        </FormControl>
    )
}

const HasNoInitialProductInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const isCampaignHasNoInitialProductInputVisible =
        !dropEvent.approved && !dropEvent.initialProduct?.length
    if (!isCampaignHasNoInitialProductInputVisible) {
        return null
    }
    return (
        <BooleanInput
            label="Campaign has no initial product"
            source="campaignHasNoInitialProduct"
        />
    )
}

const HasNoValidDaresInput = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const isCampaignHasNoValidDaresInputVisible =
        !dropEvent.approved && !hasValidDares(dropEvent)
    if (!isCampaignHasNoValidDaresInputVisible) {
        return null
    }
    return (
        <BooleanInput
            label="Campaign has no valid dares"
            source="campaignHasNoValidDares"
        />
    )
}

const PointsSection = () => {
    const dropEvent = useRecordContext<DropEvent>()
    const { helperText, setHelperText } = useHelperText(
        pointsToDollarsHandler,
        dropEvent.pointsLimit ?? 0
    )
    const { setValue } = useFormContext()

    return (
        <>
            {/* @ts-ignore */}
            <FormDataConsumer<{
                awardPoints: boolean
                pointsCalculationMethod: PointsCalculationMethod
            }>>
                {({ formData }) => {
                    return (
                        formData.awardPoints && (
                            <>
                                <NumberInput
                                    label="Campaign points limit"
                                    source="pointsLimit"
                                    onChange={setHelperText}
                                    helperText={helperText}
                                    validate={minValue(1)}
                                    format={(val) => Math.floor(val)}
                                    defaultValue={0}
                                />
                                <NumberInput
                                    style={{ display: 'block' }}
                                    label="Points reservation time (hours)"
                                    source="pointsReservationHours"
                                />
                                <BooleanInput
                                    label="Drop points applications enabled"
                                    source="pointsApplicationsEnabled"
                                />
                                <RadioButtonGroupInput
                                    label="Points calculation method"
                                    source="pointsCalculationMethod"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (
                                            e.target?.value !==
                                            PointsCalculationMethod.CPVH
                                        ) {
                                            setValue(
                                                'costPerViewHourPoints',
                                                undefined
                                            )
                                        }
                                        if (
                                            e.target?.value ===
                                            PointsCalculationMethod.CPVH
                                        ) {
                                            setValue('costPerViewHourPoints', 0)
                                        }
                                    }}
                                    choices={[
                                        {
                                            id: PointsCalculationMethod.DEFAULT,
                                            name: 'Default',
                                        },
                                        {
                                            id: PointsCalculationMethod.CPVH,
                                            name: 'CPVH',
                                        },
                                    ]}
                                />
                                {formData.pointsCalculationMethod ===
                                    PointsCalculationMethod.CPVH && (
                                    <NumberInput
                                        label="Cost per view hour (in points)"
                                        source="costPerViewHourPoints"
                                    />
                                )}
                            </>
                        )
                    )
                }}
            </FormDataConsumer>
            <AdminAsyncFilterAutocomplete
                label="Featured creator"
                optionLabel="mnemonicId"
                source="featuredCreator"
                multiple={false}
                asyncOptionsFn={async (searchPhrase) => {
                    const twitchUsers = await DaredropApi.searchTwitchUsers(
                        searchPhrase
                    )
                    return twitchUsers.map(
                        ({
                            id: platformId,
                            display_name,
                            broadcaster_login,
                        }: TwitchUser) => ({
                            mnemonicId: broadcaster_login,
                            displayName: display_name,
                            platformId,
                            platform: 'twitch',
                        })
                    )
                }}
            />
        </>
    )
}

export default ManageCampaign
